$(function() {

    // Initialize:
    ds81_init_level_menu($('.nav-mobile .level-menu'));

    // Set active path:
    ds81_levelmenu_set_active_view();

    // Mobile nav open close:
    $('.oc-nav-trigger').on('click.nav-main-toggle',function () {
        $(this).find('.hamburger').toggleClass('is-active');
        if($(this).find('.hamburger').hasClass('is-active'))
        {
            $('.nav-mobile').addClass('show');
            $('.nav-mobile-close-layer').addClass('show');
            $('html').addClass('mobile-nav-show');
        }
        else
        {
            $('.nav-mobile').removeClass('show');
            $('.nav-mobile-close-layer').removeClass('show');
            $('html').removeClass('mobile-nav-show');
        }
    });
    $('.nav-mobile-close-layer').on('click.nav-main-close-via-layer', function () {
        $('.oc-nav-trigger').first().trigger('click');
    });

    // $('.oc-nav-trigger').first().trigger('click');

});

function ds81_init_level_menu($levelMenu)
{

    if($levelMenu.length > 0)
    {
        // Init sub level animation triggers:
        $levelMenu.find('.level-select').each(function () {
            $(this).on('click.dsMobileLevelSelect', function () {
                // Get related sub level section:
                var subLevelId = $(this).closest('li').data('node');
                $subSection = $(this).closest('.level-menu').find('section[data-parent="' + subLevelId + '"]');
                if($subSection.length > 0)
                {
                    var targetLevelInt = parseInt($subSection.data('level'));
                    var levelPString = ((100 * targetLevelInt) * -1) + '%';
                    $otherSectionsOnSameLevel = $(this).closest('.level-menu').find('section[data-parent].l' + targetLevelInt).not('section[data-parent="' + subLevelId + '"]').hide();
                    $subSection.show();
                    $(this).closest('.level-menu').find('.mnav-main-mover').css('left', levelPString);

                }
            }).css('cursor', 'pointer');
        });
        $levelMenu.find('.level-back').each(function () {
            $(this).on('click.dsMobileLevelBack', function () {
                // Get parent section's level id:
                var levelOffset = parseInt($(this).closest('.level-menu').find('.mnav-main-mover').data('level-offset'));
                var myLevelInt = parseInt($(this).closest('section').data('level'));
                if(levelOffset > 0)
                {
                    if(myLevelInt == 2)
                    {
                        myLevelInt -= levelOffset;
                    }
                }
                var levelPString = ((100 * (myLevelInt - 1)) * -1) + '%';
                console.log(myLevelInt, levelPString, levelOffset);
                $(this).closest('.level-menu').find('.mnav-main-mover').css('left', levelPString);
                $allSubSections = $(this).closest('.level-menu').find('section[data-parent]');
            }).css('cursor', 'pointer');
        });
    }
    $(window).on('resize.dsMobileNavResize', ds81_levelmenu_resize);
    ds81_levelmenu_resize();
    setTimeout(ds81_levelmenu_resize, 10);
}

function ds81_levelmenu_resize() {
    try{
        $allSubSections = $('.level-menu').find('section[data-parent],.mnav-root-list');
        $allSubSections.css('height', '1px');
        var h = $('.level-menu').parent().outerHeight();// - $levelMenu.find('.mnav-header').outerHeight() - $levelMenu.find('.mnav-footer').outerHeight();
        $allSubSections.css('height',  h + 'px');
    }catch(e){}
}

function ds81_levelmenu_set_active_view() {

    // Activate active nodes:
    if($('.mnav-root-list .active .level-select').length > 0)
    {

        $('.mnav-root-list .active .level-select').each(function () {
            $(this).trigger('click');
            var subLevelId = $(this).closest('li').data('node');
            $subSection = $(this).closest('.level-menu').find('section[data-parent="' + subLevelId + '"]');
            if($subSection.length > 0)
            {
                ds81_levelmenu_set_active_view_recurser($subSection);
            }
        });
    }

}

function ds81_levelmenu_set_active_view_recurser($section)
{
    $section.find('.active .level-select').each(function () {
        $(this).trigger('click');
        var subLevelId = $(this).closest('li').data('node');
        $subSection = $(this).closest('.level-menu').find('section[data-parent="' + subLevelId + '"]');
        if($subSection.length > 0)
        {
            ds81_levelmenu_set_active_view_recurser($subSection);
        }
    });
}
