
import {formValidation, plugins} from "../formvalidation_1_8_1/es6/index";
import Bootstrap5 from "../formvalidation_1_8_1/es6/plugins/Bootstrap5";


import * as Ladda from 'ladda';
import _ from "underscore";

$(document).ready(function() {

    // Init form validation on contacts page:
    if($('#contactsForm').length > 0)
    {
        // $('.ds-selectpicker-cf').selectpicker({
        //     style: 'form-control',
        //     width: '100%'
        // }).on('loaded.bs.select', function () {
        //     // var ph = $(this).attr('data-onselect-placeholder');
        //     // $(this).parent().find('.btn.dropdown-toggle').attr('data-onselect-placeholder', ph);
        // }).on('changed.bs.select', function () {
        //     setTimeout(dsContactsFormSizeTextarea, 10);
        //     $(this).blur();
        //     console.log($(this));
        // });

        dsInitContactsFormValidation();

        // Sizer:
        if($('#contactsForm').find('textarea.size-to-col').length > 0)
        {
            $(window).on('resize.dsContactsFormSizeTextarea', dsContactsFormSizeTextarea);
            dsContactsFormSizeTextarea();

            $('#contactsForm').find('input').on('input', dsContactsFormSizeTextarea);
            $('#contactsForm').find('textarea').on('input', dsContactsFormSizeTextarea);
            $('#contactsForm').find('select').on('input', dsContactsFormSizeTextarea);

        }
    }

});

function dsContactsFormSizeTextarea(){

    $('#contactsForm').each(function () {
       if($(this).find('textarea.size-to-col').length > 0)
       {
           var $ta = $(this).find('textarea.size-to-col');
           var $r = $ta.closest('form');
           var $refCol = $r.find('.measure-col');
           var $refField = $r.find('.measure-input');


           var measureColPosition = $refCol.offset().top;
           var measureFieldPosition = $refField.offset().top;
           var blockHeight = measureFieldPosition - measureColPosition + $refField.outerHeight();

           $ta.css('height', blockHeight + 'px');

       }
    });
}

function dsInitContactsFormValidation(){

    var $form = $('#contactsForm');
    var form = $form.get(0);

    $form.find('[name="i_anfrage"]').selectpicker({
        style: 'form-control',
        width: '100%'
    }).on('changed.bs.select', function () {
        setTimeout(dsContactsFormSizeTextarea, 10);
        $(this).selectpicker('toggle');
    });

    var fv = formValidation(

            form,
            {
                fields: {
                    i_anfrage: {
                        validators: {
                            notEmpty: {
                                message: 'Bitte wählen Sie eine Art der Anfrage aus.'
                            },
                            srv: {}
                        }
                    },
                    i_name: {
                        validators: {
                            notEmpty: {
                                message: 'Bitte geben Sie Ihren Namen ein.'
                            },
                            srv: {}
                        }
                    },
                    i_email: {
                        validators: {
                            notEmpty: {
                                message: 'Bitte geben Sie Ihre E-Mail-Adresse ein.'
                            },
                            emailAddress: {
                                message: 'Die eingegebene E-Mail-Adresse ist ungültig.'
                            },
                            srv: {}
                        }
                    },
                    i_message: {
                        validators: {
                            notEmpty: {
                                message: 'Bitte geben Sie eine Nachricht ein.'
                            },
                            srv: {}
                        }
                    },
                    cb_dp: {
                        validators: {
                            notEmpty: {
                                message: 'Zum Versand dieser Nachricht müssen Sie der Verarbeitung der eingegebenen Daten zustimmen.'
                            },
                            srv: {}
                        }
                    },
                    i_superfield: {
                        enabled: false,
                        validators: {
                            stringLength: {
                                max: 0,
                                min: 0,
                                message: 'Dieses Feld darf keinen Inhalt haben!'
                            }
                        }
                    }
                },
                plugins: {
                    trigger: new plugins.Trigger(),
                    bootstrap: new Bootstrap5({
                        rowSelector: '.mb-3',
                    }),
                    submitButton: new plugins.SubmitButton(),
                    defaultSubmit: new plugins.DefaultSubmit(),
                    sequence: new plugins.Sequence({
                        enabled: true,
                    }),
                    declarative: new plugins.Declarative({
                        html5Input: false
                    }),
                },
            }).on('core.form.invalid', function() {
                $(window).trigger('resize.dsContactsFormSizeTextarea');
                setTimeout(dsContactsFormSizeTextarea, 10);
            }).on('core.form.validating', function() {
                $(window).trigger('resize.dsContactsFormSizeTextarea');
                setTimeout(dsContactsFormSizeTextarea, 10);
            }).on('core.form.validated', function() {
                $(window).trigger('resize.dsContactsFormSizeTextarea');
                setTimeout(dsContactsFormSizeTextarea, 10);
            }).on('core.form.valid', function() {
                $(window).trigger('resize.dsContactsFormSizeTextarea');
                setTimeout(dsContactsFormSizeTextarea, 10);
            });

        setTimeout(dsContactsFormSizeTextarea, 10);
}
