// Import all plugins
import 'jquery-sticky';
import * as bootstrap from 'bootstrap';
import "./import-jquery";
import "./import-bootstrap";
import typeWatch from 'jquery.typewatch';
// import mixitup from 'mixitup';
// import mixitupPagination from './mixitup-pagination';
// mixitup.use(mixitupPagination);

import zxcvbn from 'zxcvbn/dist/zxcvbn';
import selectpicker from 'bootstrap-select';


import 'jquery-focuspoint';
import {getBreakPointName, isWithinBreakpoint} from "./checkBootstrapBreakpoint";
import Cookies from "js-cookie";
import deparam from "jquery-deparam";
import Plyr from "plyr";
import fancybox from '@fancyapps/fancybox';

// import ScrollReveal from "scrollreveal";
import BootstrapDialog from 'bootstrap4-dialog';

import _ from "underscore";

import "./jq-mobile-menu";

// import ds81_initAutovalidatorForm from './jq-autovalidator-form_import.js';
// import {formValidation, plugins} from "../formvalidation_1_8_1/es6/";
// import Bootstrap from "../formvalidation_1_8_1/es6/plugins/Bootstrap";
// import * as Ladda from "ladda";
// import moment from 'moment';
// import 'moment/locale/de';
// moment.locale('de');

import "./jq-contactform";
import Lazy from "jquery-lazy";

// import "../../../node_modules/daterangepicker/daterangepicker";
/**
 * forEach implementation for Objects/NodeLists/Arrays, automatic type loops and context options
 *
 * @private
 * @author Todd Motto
 * @link https://github.com/toddmotto/foreach
 * @param {Array|Object|NodeList} collection - Collection of items to iterate, could be an Array, Object or NodeList
 * @callback requestCallback      callback   - Callback function for each iteration.
 * @param {Array|Object|NodeList} scope=null - Object/NodeList/Array that forEach is iterating over, to use as the this value when executing callback.
 * @returns {}
 */
var lm_forEach=function(t,o,r){if("[object Object]"===Object.prototype.toString.call(t))for(var c in t)Object.prototype.hasOwnProperty.call(t,c)&&o.call(r,t[c],c,t);else for(var e=0,l=t.length;l>e;e++)o.call(r,t[e],e,t)};


export default (window._ = _, window.loadJsCssFile = loadJsCssFile, window.winDim = winDim, window.dscookiebar_has_consent = dscookiebar_has_consent);


$(function() {


    // Activate focuspoint on images:
    $('.focuspoint').focusPoint();

    // Init header depending on viewport:
    $(window).on('resize.dsHeaderCheck', dsHeaderCheck);
    $(window).on('load', dsHeaderCheck);
    $(window).on('scroll', dsHeaderCheck);
    dsHeaderCheck();

    // Lazy loading:
    $("img.lazy").show().Lazy({

        // Afterload: Focuspoint support?
        // afterLoad: function(element) {
        //     var imageSrc = element.data('src');
        //     console.log('image "' + imageSrc + '" was loaded successfully');
        // },

    });

    // Load postInit scripts:
    if(typeof loadJsCssFileList !== "undefined")
    {
        for(var i in loadJsCssFileList)
        {
            var task = loadJsCssFileList[i];
            loadJsCssFile(task[0], task[1], task[2]);
        }
    }

    // Handle changes in consent selection:
    $(window).on('dscookiebar_consentchange', dscookiebar_handle_consent_change);
    dscookiebar_handle_consent_change();

    // Check and handle cookie bar show/hide:
    if($('#dscookiebar_consent').length > 0)
    {
        // Consent version id:
        var cookieVersion = $('#dscookiebar_consent').first().data('version');

        // Cookie path:
        var cookiePath = typeof path_root !== "undefined" ? path_root : '';

        // Read cookie:
        if(typeof Cookies.get('dscookieconsent_version', {path: cookiePath}) === "undefined" || Cookies.get('dscookieconsent_version', {path: cookiePath}) != cookieVersion)
        {
            $('#dscookiebar_consent').each(function () {

                // Prevent form submit:
                $(this).find('form').submit(function(e){
                    e.preventDefault();
                });

                // Link select checkboxes:
                $(this).find('input[type="checkbox"]').each(function (){
                    if(typeof $(this).attr('checked') !== "undefined"){
                        $(this).prop('checked', true);
                    }
                });
                $(this).find('input[type="checkbox"]').on('change', function () {
                    // Transfer status to possibly present cookie bar:
                    $('#dscookiebar_onpage_consent input[type="checkbox"][name="' + $(this).attr('name') + '"]').prop('checked', $(this).prop('checked'));
                });

                $(this).removeClass('d-none');
                $(this).find('.content-select-consent').addClass('d-none');
                $(this).find('.content-default').removeClass('d-none');

                $(this).find('.btn-accept-all').on('click.dscookiebaracceptall', dscookiebar_accept_all_handler);
                $(this).find('.btn-accept-none').on('click.dscookiebaracceptnone', dscookiebar_accept_none_handler);
                $(this).find('.btn-make-choice').on('click.dscookiebarmakechoice', dscookiebar_show_select_consent_content);
                $(this).find('.btn-cancel-consent-select').on('click.dscookiebarcancel', dscookiebar_show_default_content);
                $(this).find('.btn-save-consent-settings').on('click.dscookiebarsaveconsent', dscookiebar_save_consent);

                $('footer').css('margin-bottom', $(this).outerHeight(true) + 'px');

            });

        }

    }

    // Link onpage component:
    if($('#dscookiebar_onpage_consent').length > 0)
    {
        $('#dscookiebar_onpage_consent').each(function () {

            // Prevent form submit:
            $(this).submit(function(e){
                e.preventDefault();
            });

            // Link select checkboxes:
            $(this).find('input[type="checkbox"]').each(function (){
                if(typeof $(this).attr('checked') !== "undefined"){
                    $(this).prop('checked', true);
                }
            });

            // Process checkboxes:
            $(this).find('input[type="checkbox"]').each(function () {

                // Set initial state:
                if(dscookiebar_has_consent($(this).attr('name')))
                {
                    $(this).prop('checked', true);
                }


                // Link checkbox to transfer state to cookie bar:
                $(this).on('change', function () {

                    // Transfer status to possibly present cookie bar:
                    $('#dscookiebar_consent input[type="checkbox"][name="' + $(this).attr('name') + '"]').prop('checked', $(this).prop('checked'));

                    // Check status:
                    dscookiebar_onpage_check_status();

                });

            });

            // Link save button:
            $(this).find('.btn-save-consent-settings').on('click.dscookiebarsaveconsent', dscookiebar_save_consent);

        });

        // Listen to change events to update onpage status:
        $(window).on('dscookiebar_consentchange', function () {

            // Check status:
            dscookiebar_onpage_check_status();

        });

        // Initial status check:
        dscookiebar_onpage_check_status();

    }



    $('.videocontainer').each(function(){
        $(this).find('.videobox').each(function () {
            ds81_init_videobox($(this));
        });
    });

    // Initialize square gallery:
    if($('.square-gallery').length > 0)
    {
        $(window).on('resize.ds81_square_gallery_sizer', ds81_square_gallery_sizer);
        ds81_square_gallery_sizer();
    }

    // Scrollreveal:
    // $('.srev-p p').addClass('srev');
    // ScrollReveal().reveal('.srev', { delay: 100, distance: '50px', reset: false, interval: 100 });


    if(dscookiebar_has_consent('gmaps'))
    {
        mapInitRun();
    }

    $(window).on('dscookiebar_consentchange', function () {
        if(dscookiebar_has_consent('gmaps'))
        {
            mapInitRun();
        }
    });

});


function ds81_square_gallery_sizer() {
    $('.square-gallery-item').each(function () {
        // $(this).find('.square-gallery-item-inner').css('height', '');
        // $(this).find('.square-gallery-item-inner').css('height', $(this).width() + 'px');

        $(this).find('.square-gallery-item-inner').each(function () {

            if($(this).hasClass('focuspoint'))
            {
                $(this).data('focusPoint').adjustFocus();
            }

        });


    });
}



function dsSetImageHeights()
{
    // Adjust images with height reference:
    $('[data-adjust-image-height-ref]').each(function () {
        var $root = $(this).parent();
        if(typeof $(this).data('adjust-image-height-root') !== "undefined")
        {
            $root = $(this).closest($(this).data('adjust-image-height-root'));
        }
        var $ref = $root.find('[data-height-for]').first();
        if($ref.length > 0)
        {
            $(this).css('height', $ref.innerHeight() + 'px');
        }

    });

    // Set image heights accoring to breakpoint (supersedes height reference);
    $('[data-i-height-xs],[data-i-height-sm],[data-i-height-md],[data-i-height-lg],[data-i-height-xl]').css('overflow-y', 'visible');
    var bb = getBreakPointName();
    switch(bb)
    {
        case('xs'):
            $('[data-i-height-xs]').each(function () {
                $(this).css('height', $(this).data('i-height-xs') + 'px');
            });
            break;
        case('sm'):
            $('[data-i-height-sm]').each(function () {
                $(this).css('height', $(this).data('i-height-sm') + 'px');
            });
            break;
        case('md'):
            $('[data-i-height-md]').each(function () {
                $(this).css('height', $(this).data('i-height-md') + 'px');
            });
            break;
        case('lg'):
            $('[data-i-height-lg]').each(function () {
                $(this).css('height', $(this).data('i-height-lg') + 'px');
            });
            break;
        default:
            $('[data-i-height-xl]').each(function () {
                $(this).css('height', $(this).data('i-height-xl') + 'px');
            });
            break;
    }
    // $('[data-i-height-xs],[data-i-height-sm],[data-i-height-md],[data-i-height-lg],[data-i-height-xl]').css('overflow-y', 'hidden');

    // Trigger re-init on image load:
    $('img').each(function () {
        if(!$(this).hasClass('load-init') && !$(this).hasClass('loaded'))
        {
            $(this).addClass('load-init');
            $(this).one("load", function () {
                if(this.complete) {
                    $(this).addClass('loaded').removeClass('load-init');
                    dsSetImageHeights();
                }
            });
        }
    });

}


function dscookiebar_handle_consent_change()
{
    // Update onpage instances:
    var $targets = $('body').find('.dscookiebar,.consent-item-list-onpage');
    $targets.find('input[type="checkbox"]').each(function () {

        // Set current state:
        if (dscookiebar_has_consent($(this).attr('name'))) {
            $(this).prop('checked', true);
        }
        else
        {
            $(this).prop('checked', false);
        }

    });


    // Google Analytics:
    if(dscookiebar_has_consent('ga'))
    {
        dscookiebar_load_and_fire_tracking_calls();
    }

    // Show/hide consent elements:
    $('[data-consent-required]').each(function (){

        // Get consent id:
        var myConsent = $(this).data('consent-required');

        $('.addconsent').on('click', function (e){
            e.preventDefault();
            dscookiebar_add_consent($(this).data('addconsent'));
        });

        if(dscookiebar_has_consent(myConsent))
        {
            // Manage show/hide:
            $(this).find('[data-ifconsent="yes"]').removeClass('d-none');
            $(this).find('[data-ifconsent="no"]').addClass('d-none');

            // Parse template:
            var pluginTemplate = $(this).find('[data-ifconsent="yes"] .plugin-template').first();
            if(pluginTemplate.length > 0)
            {
                // console.log('IN');
                var tpl = _.template(pluginTemplate.html());
                var tplRendered = tpl({});
                $(this).find('[data-ifconsent="yes"]').html(tplRendered);
            }
        }
        else
        {
            // Manage show/hide:
            $(this).find('[data-ifconsent="yes"]').addClass('d-none');
            $(this).find('[data-ifconsent="no"]').removeClass('d-none');
        }

    });

}

function dscookiebar_add_consent(addConsent, callback){

    var $bar = $('body .dscookiebar').first();

    // Consent version id:
    var cookieVersion = $bar.data('version');

    // All IDs:
    var cookieIDs = $bar.data('cookies-list');

    // Consent found?
    var newConsentFound = false;

    var cookieStringList = [];
    for(var i in cookieIDs)
    {
        if(cookieIDs[i] == addConsent)
        {
            newConsentFound = true;
        }
        var setValue = 0;
        if(dscookiebar_has_consent(cookieIDs[i]) || cookieIDs[i] == addConsent)
        {
            setValue = 1;
        }
        cookieStringList.push(cookieIDs[i] + '=' + setValue);
    }
    var cookieString = cookieStringList.join('&');

    // Cookie path:
    var cookiePath = typeof path_root !== "undefined" ? path_root : '';

    // Set cookie using js-cookie helper (https://github.com/js-cookie/js-cookie)
    Cookies.set('dscookieconsent', cookieString, { expires: 365, path: cookiePath });
    Cookies.set('dscookieconsent_version', cookieVersion, { expires: 365, path: cookiePath });

    // Trigger event:
    $(window).trigger('dscookiebar_consentchange');

    // Callback?
    if(typeof callback == 'function')
    {
        // console.log('FN A', arguments);
        callback(arguments[2], arguments[3], arguments[4], arguments[5]);
    }

}


function dscookiebar_onpage_check_status() {

    // Check current status and enable save button if onpage settings do not match current consent:
    if($('#dscookiebar_onpage_consent').length > 0)
    {
        $('#dscookiebar_onpage_consent').each(function () {

            // Flag: All settings match current selection:
            var allMatch = true; // Assume true first.

            // Process checkboxes and check selection:
            $(this).find('input[type="checkbox"]').each(function () {

                // Check status:
                if(dscookiebar_has_consent($(this).attr('name')) != $(this).prop('checked'))
                {
                    // No match. Checkbox state does not equal current consent.
                    allMatch = false;
                }

            });

            // Set button status:
            $(this).find('.btn-save-consent-settings').prop('disabled', allMatch);
            if(allMatch)
            {
                $(this).find('.message-all-up-to-date').removeClass('d-none');
            }
            else
            {
                $(this).find('.message-all-up-to-date').addClass('d-none');
            }

        });
    }

}

function dscookiebar_has_consent(which) {
    var allConsents = dscookiebar_get_consents();
    return jQuery.inArray( which, allConsents) >= 0;
}

function dscookiebar_get_consents() {

    // Consent version id:
    var cookieVersion = $('#dscookiebar_consent').first().data('version');

    // Cookie path:
    var cookiePath = typeof path_root !== "undefined" ? path_root : '';

    // Prepeare return array:
    var ret = [];

    // Read cookie:
    if(typeof Cookies.get('dscookieconsent_version', {path: cookiePath}) !== "undefined" && Cookies.get('dscookieconsent_version', {path: cookiePath}) == cookieVersion)
    {
        // Get cookie using js-cookie helper (https://github.com/js-cookie/js-cookie)
        var consentData = Cookies.get('dscookieconsent', { path: cookiePath });

        // Data from cookie obtained?
        if(typeof consentData !== "undefined")
        {
            var consentArray = deparam(consentData);
            for(var i in consentArray)
            {
                if(parseInt(consentArray[i]) > 0)
                {
                    ret.push(i);
                }
            }
        }

    }

    // Return given consents:
    return ret;

}

function dscookiebar_hide_bar($bar) {

    $('html').removeClass('dscookiebar-show-cover');

    $bar.find('.inner').animate({
        // height: 0,
        opacity: 0,
    }, {
        duration: 300,
        complete: function () {
            $(this).closest('.dscookiebar').hide();
        }
        // ,
        // step: function (now, tween) {
        // $(this).parent().css('height', $(this).outerHeight() + 'px');
        // }
    });

}


function dscookiebar_accept_none_handler(e) {

    if(typeof e !== "undefined")
    {
        e.preventDefault();
    }

    // Get references:
    var $button = $(e.currentTarget);
    var $bar = $button.closest('.dscookiebar');

    dscookiebar_hide_bar($bar);

    // Consent version id:
    var cookieVersion = $bar.data('version');

    // Empty cookie:
    var cookieString = '';

    // Cookie path:
    var cookiePath = typeof path_root !== "undefined" ? path_root : '';

    // Set cookie using js-cookie helper (https://github.com/js-cookie/js-cookie)
    Cookies.set('dscookieconsent', cookieString, { expires: 365, path: cookiePath });
    Cookies.set('dscookieconsent_version', cookieVersion, { expires: 365, path: cookiePath });

    // Trigger event:
    $(window).trigger('dscookiebar_consentchange');

}

function dscookiebar_accept_all_handler(e) {

    if(typeof e !== "undefined")
    {
        e.preventDefault();
    }

    // Get references:
    var $button = $(e.currentTarget);
    var $bar = $button.closest('.dscookiebar');

    dscookiebar_hide_bar($bar);

    // Consent version id:
    var cookieVersion = $bar.data('version');

    // All IDs:
    var cookieIDs = $bar.data('cookies-list');
    var cookieStringList = [];
    for(var i in cookieIDs)
    {
        cookieStringList.push(cookieIDs[i] + '=1');
    }
    var cookieString = cookieStringList.join('&');

    // Cookie path:
    var cookiePath = typeof path_root !== "undefined" ? path_root : '';

    // Set cookie using js-cookie helper (https://github.com/js-cookie/js-cookie)
    Cookies.set('dscookieconsent', cookieString, { expires: 365, path: cookiePath });
    Cookies.set('dscookieconsent_version', cookieVersion, { expires: 365, path: cookiePath });

    // Trigger event:
    $(window).trigger('dscookiebar_consentchange');

}

function dscookiebar_show_select_consent_content(e) {

    e.preventDefault();

    var $button = $(e.currentTarget);
    var $bar = $button.closest('.dscookiebar');

    $bar.find('.content-select-consent').removeClass('d-none');
    $bar.find('.content-default').addClass('d-none');
    $('html').addClass('dscookiebar-show-cover');

}

function dscookiebar_show_default_content(e) {

    e.preventDefault();

    var $button = $(e.currentTarget);
    var $bar = $button.closest('.dscookiebar');

    $bar.find('.content-select-consent').addClass('d-none');
    $bar.find('.content-default').removeClass('d-none');
    $('html').removeClass('dscookiebar-show-cover');
}

function dscookiebar_save_consent(e) {

    e.preventDefault();

    var $button = $(e.currentTarget);
    var $form = $button.closest('form');

    // Find bar to obtain data:
    var $bar = $('#dscookiebar_consent').first();

    var checked = [];
    $form.find('input.consent-check:checked').each(function () {
        checked.push($(this).attr('name'));
    });

    dscookiebar_hide_bar($bar);

    // Consent version id:
    var cookieVersion = $bar.data('version');

    // All IDs:
    var cookieIDs = $bar.data('cookies-list');
    var mandatoryIDs = $bar.data('mandatory-list');
    var cookieStringList = [];
    for(var i in cookieIDs)
    {
        var myId = cookieIDs[i];
        var myState = 0;

        // Selected or mandatory?
        if(jQuery.inArray( myId, checked) >= 0 || jQuery.inArray( myId, mandatoryIDs) >= 0)
        {
            myState = 1;
        }

        cookieStringList.push(myId + '=' + myState);
    }
    var cookieString = cookieStringList.join('&');

    // Cookie path:
    var cookiePath = typeof path_root !== "undefined" ? path_root : '';

    // Set cookie using js-cookie helper (https://github.com/js-cookie/js-cookie)
    Cookies.set('dscookieconsent', cookieString, { expires: 365, path: cookiePath });
    Cookies.set('dscookieconsent_version', cookieVersion, { expires: 365, path: cookiePath });

    // Trigger event:
    $(window).trigger('dscookiebar_consentchange');
}

// var dscookiebar_current_page_tracked = false;
// var dsGtag;
// function dscookiebar_load_and_fire_tracking_calls()
// {
//
//     // No tracking if backend user is logged in:
//     if(typeof dp_prevent_count !== "undefined") return false;
//
//     // dp_ga_uid set and has content?
//     if(typeof dp_ga_uid === 'undefined' || dp_ga_uid.length < 1) return false;
//
//     if(dscookiebar_current_page_tracked === false)
//     {
//         dscookiebar_current_page_tracked = true;
//         loadJsCssFile('https://www.googletagmanager.com/gtag/js?id=' + dp_ga_uid, 'js', function () {
//             window.dataLayer = window.dataLayer || [];
//             function gtag(){dataLayer.push(arguments);}
//             gtag('js', new Date());
//             gtag('config', dp_ga_uid, { 'anonymize_ip': true });
//             dsGtag = gtag;
//         });
//     }
// }



// File loader:
export var _us = _;
function loadJsCssFile(filename, filetype, callback){
    if (filetype=="js"){ //if filename is a external JavaScript file
        var fileref=document.createElement('script')
        fileref.setAttribute("type","text/javascript")
        fileref.setAttribute("src", filename)
    }
    else if (filetype=="css"){ //if filename is an external CSS file
        var fileref=document.createElement("link")
        fileref.setAttribute("rel", "stylesheet")
        fileref.setAttribute("type", "text/css")
        fileref.setAttribute("href", filename)
    }

    if(typeof callback === "function")
    {
        fileref.onload = callback;
    }

    if (typeof fileref!="undefined")
        document.getElementsByTagName("head")[0].appendChild(fileref)
}

function winDim(){
    // compute window width:
    var w = window,
        d = document,
        e = d.documentElement,
        g = d.getElementsByTagName('body')[0],
        winWidth = w.innerWidth || e.clientWidth || g.clientWidth,
        winHeight = w.innerHeight|| e.clientHeight|| g.clientHeight;

    return {width: winWidth, height: winHeight };
}



function dsHeaderCheck() {

    var $h = $('header');

    if(!$h.hasClass('ds-header-init'))
    {
        $h.addClass('ds-header-init');
        $h.before('<div class="header-height-fill"></div>');
        var $hF = $('.header-height-fill');
        $hF.css('min-height', Math.floor($h.outerHeight(true)) + 'px');
        $h.addClass('fixed-top');

        $h.attr('data-default-pad-top', parseInt($h.css('padding-top')));
        $h.attr('data-default-pad-bottom', parseInt($h.css('padding-bottom')));

        $("aside").unstick();

        if(isWithinBreakpoint('md') || isWithinBreakpoint('lg') || isWithinBreakpoint('xl'))
        {
            $("aside").sticky({topSpacing: 110, zIndex: 99}).on('sticky-start', function() { $(window).trigger('ds-sticky-header-start'); console.log('ass'); }).on('sticky-end', function() { $(window).trigger('ds-sticky-header-end') });
        }
        else
        {
        }
    }

    var padTopMinFactor = 0.4;
    var padBottomMinFactor = 0.4;
    var scrollMaxDown = 60;
    var scrollIsDown = Math.min(window.scrollY, scrollMaxDown);
    var scrollDownPercent = (100 / scrollMaxDown) * scrollIsDown;

    var defaultTopPad = $h.data('default-pad-top');
    var defaultBottomPad = $h.data('default-pad-bottom');

    var padTopMinValue = defaultTopPad * padTopMinFactor;
    var padBottomMinValue = defaultBottomPad * padBottomMinFactor;

    var padTopDistValue = defaultTopPad - padTopMinValue;
    var padBottomDistValue = defaultBottomPad - padBottomMinValue;

// console.log('dp', $h.data('default-pad-top'));
// console.log('dp', padBottomDistValue);

    var padTopScrollValue = defaultTopPad - ((padTopDistValue/100) * scrollDownPercent);
    var padBottomScrollValue = defaultBottomPad - ((padBottomDistValue/100) * scrollDownPercent);

    // console.log('padTopScrollValue', padTopScrollValue);
    // console.log('padBottomScrollValue', padBottomScrollValue);

    $h.css('padding-top', padTopScrollValue + 'px');
    $h.css('padding-bottom', padBottomScrollValue + 'px');



}


function ds81_init_videobox($target) {


    $target.on('click.ds_video_init', function (e) {

        $(this).off('click.ds_video_init');

        // Check cookie consent:
        if(dscookiebar_has_consent($(this).data('videotype')))
        {
            ds81_init_videobox_continue($(e.currentTarget));
        }
        else
        {
            $(this).parent().find('.cookie-agreement').removeClass('d-none').addClass('d-flex');
            $(this).parent().find('.cookie-agreement .btn-add-agreement').on('click.dscookieagreement', function (){
                var $myRoot = $(this).closest('.videocontainer').find('.videobox');
                dscookiebar_add_consent($myRoot.data('videotype'), function (_$myRoot){
                    _$myRoot.parent().find('.cookie-agreement').remove();
                    ds81_init_videobox_continue(_$myRoot);
                }, $myRoot);
            });
        }

    });


}

function ds81_is_iOS() {
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

var plyrRunningInstance;

function ds81_init_videobox_continue($target) {


    $target.addClass('loading');
    var $root = $target.closest('.video-root').first();
    var tplHtml = $root.find('.video-embed').first().html();
    var tpl = _.template(tplHtml);
    var tplRendered = tpl({});

    $root.find('.videobox').append('<div class="videoembed"></div>');
    $root.find('.videobox .videoembed').html(tplRendered);
    var $plyrMain = $root.find('.videobox .videoembed .plyr_main').first();
    console.log('CONTINUE', $plyrMain.attr('id'));
    var p = new Plyr('#' + $plyrMain.attr('id'), {
        iconUrl: path_root + 'lib/plyr/plyr.svg',
        blankVideo: path_root + 'lib/plyr/blank.mp4',
        debug: false
    });

    // $plyrMain.css('opacity', 0);
    // $root.find('.loading-icon').removeClass('d-none');
    $root.find('.play-icon').addClass('d-none');
    p.on('error', function (e) {

        var $plyr = $(e.target);
        var $main = $plyr.closest('.videobox');
        $main.removeClass('loading');
        $main.find('.loading-icon').addClass('d-none');
        $main.find('.play-icon.d-none').removeClass('d-none');
        $main.find('.videoembed').remove();
        ds81_init_videobox($main);
        console.error('plyr error', e);


    });

    $plyrMain.css('opacity', 1);
    $root = $plyrMain.closest('.videocontainer');
    $root.find('.loading-icon').addClass('d-none');

    // if on ios: native player required. Show contents.
    if(ds81_is_iOS())
    {
        $plyrMain.css('opacity', 1);
        $root = $plyrMain.closest('.videocontainer');
        $root.find('.loading-icon').addClass('d-none');
    }

    p.on('ready', function (e) {
        var instance = e.detail.plyr;


        console.log("VIDEO READY FOR PLAYBACK", e, instance, instance.playing);

        var $plyr = $(e.target);
        var $main = $plyr.closest('.videobox');
        $main.removeClass('loading');


        instance.on('canplay', function (e) {
            console.log('canplay', e);
        });
        instance.on('statechange', function (e) {
            console.log('STATECHANGE', e);
        });
        instance.on('playing', function (e) {

            console.log("VIDEO PLAYING");

            $main.addClass('play-was-started');

            var instance = e.detail.plyr;

            if(plyrRunningInstance && !$(plyrRunningInstance.media).is($(instance.media)))
            {
                console.log('PAUSING OTHER INSTANCE', plyrRunningInstance);
                plyrRunningInstance.pause();
            }

            plyrRunningInstance = instance;
            console.log('UPDATING PLAYING INSTANCE', plyrRunningInstance);


            var $plyr = $(e.target);
            $plyr.css('opacity', 1);
            $root = $plyr.closest('.videocontainer');
            $root.find('.loading-icon').addClass('d-none');
            var $container = $root.closest('.image-holder');
            var $pointer = $container.find('.pointer');
            $pointer.animate({
                opacity: 0,
                right: -100
            }, 800, function (){
                $(this).remove();
            })
        });


        // If NOT on iOs: Safari bugfix: Needs an extra kick.
        if(!ds81_is_iOS())
        {
            setTimeout(function (){
                if(!instance.playing && !$main.hasClass('play-was-started'))
                {
                    $main.addClass('play-was-started');
                    instance.play();
                }
                console.log("VIDEO NOW PLAYING (1)");
            }, 1000);
            setTimeout(function (){
                if(!instance.playing && !$main.hasClass('play-was-started'))
                {
                    $main.addClass('play-was-started');
                    instance.play();
                }
                console.log("VIDEO NOW PLAYING (2)");
            }, 2000);
            setTimeout(function (){
                if(!instance.playing && !$main.hasClass('play-was-started'))
                {
                    $main.addClass('play-was-started');
                    instance.play();
                }
                console.log("VIDEO NOW PLAYING (3)");
            }, 3000);
            if(!instance.playing)
            {
                $main.addClass('play-was-started');
                console.log("VIDEO NOW PLAYING (0)");
                instance.play();
            }
        } else{
            console.log("VIDEO PLAYING?", instance.playing);
        }


    });
}


var mapLibLoaded = false;
function mapInitRun() {
    if(!mapLibLoaded)
    {
        mapLibLoaded = true;
        loadJsCssFile('//maps.google.com/maps/api/js?key=AIzaSyCWERTFpLSkUUxRuoNtatYjHpo27gyXtsY', 'js', function () {
            loadJsCssFile(path_root + 'lib/javascript/gmaps.js', 'js', function () {
                loadJsCssFile(path_root + 'lib/googlemaps-v3-utility-library/infobox/src/infobox.js', 'js', function () {
                    initMapViews();
                });
            });
        });
    }

}

/**
 * Helper method to initialize map views
 */
function initMapViews() {
    // try{

    $('.googleLocationMap').each(function() {


        if ($(this).is(':visible') == true && !$(this).hasClass('mapInitDone')) {

            var coords = $(this).data('mapcenter').split(',');
            var markerImage = $(this).data('mapmarker');
            var mapZoom = parseInt($(this).data('mapzoom'));
            var mapData = $(this).data('mapdata');

            var mapTgtHeight = Math.max(50, parseInt($(this).data('min-height')));

            if($(this).hasClass('height-from-prev-col'))
            {
                mapTgtHeight = parseInt($(this).closest('[class*="col-"]').prev('[class*="col-"]').outerHeight());
            }

            if($(this).hasClass('height-from-teaser-height-rel-row'))
            {
                var $r = $(this).closest('.row');
                var mapMeasureHeight = 0;
                $r.find('.teaser-height-rel').each(function(){
                    if($(this).outerHeight() > mapMeasureHeight)
                    {
                        mapMeasureHeight = $(this).outerHeight();
                    }
                });
                mapTgtHeight = mapMeasureHeight;
                $r.find('.teaser-height-rel').css('height', mapTgtHeight + 'px');
            }

            $(this).css('height', mapTgtHeight + 'px');

            $(this).addClass('mapInitDone');

            var dynGoogleMapObj = new GMaps({
                div: $(this).get(0),
                lat: coords[0],
                lng: coords[1],
                zoom: mapZoom,
                disableDefaultUI: false,
                scrollwheel: false,
                navigationControl: true,
                mapTypeControl: $(this).data('control-maptype') === false ? false : true,
                mapTypeControlOptions: {
                    position: google.maps.ControlPosition.RIGHT_TOP
                },
                scaleControl: false,
                streetViewControl: false,
                streetViewControlOptions: {
                    position: google.maps.ControlPosition.RIGHT_BOTTOM
                },
                draggable: $(this).data('control-move') === false ? false : true,
                zoomControl: $(this).data('control-zoom') === false ? false : true,
                zoomControlOptions: {
                    position: google.maps.ControlPosition.RIGHT_BOTTOM
                },
                fullscreenControl: false,
                styles: [
                    {
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#f5f5f5"
                            }
                        ]
                    },
                    {
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#616161"
                            }
                        ]
                    },
                    {
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "color": "#f5f5f5"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.land_parcel",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.land_parcel",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#bdbdbd"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.neighborhood",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#eeeeee"
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#757575"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.business",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#e5e5e5"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "labels.text",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#9e9e9e"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#757575"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#dadada"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#616161"
                            }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#9e9e9e"
                            }
                        ]
                    },
                    {
                        "featureType": "transit.line",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#e5e5e5"
                            }
                        ]
                    },
                    {
                        "featureType": "transit.station",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#eeeeee"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#c9c9c9"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#c72a03"
                            },
                            {
                                "saturation": -90
                            },
                            {
                                "lightness": 75
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "labels.text",
                        "stylers": [
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#9e9e9e"
                            }
                        ]
                    }
                ]
            });

            // Add markers:
            if(mapData && mapData.length > 0)
            {
                for(var i in mapData)
                {
                    var md = mapData[i];

                    // Marker:
                    var markerImageCurrent = markerImage;
                    if(md.marker_image.length > 0)
                    {
                        markerImageCurrent = md.marker_image;
                    }

                    if(typeof md.markertext !== 'undefined' && md.markertext.length > 0)
                    {
                        var contentString = '<div class="marker-content">' + md.markertext + '</div>';

                        // Get content width:
                        $(this).parent().append('<div class="marker-content marker-dimension-tester" style="display:inline-block;">' + md.markertext + '</div>');
                        var markerMaxWidth = 150;
                        var markerCalcWidth = $(this).parent().find('.marker-dimension-tester').outerWidth() + 2;
                        var markerCalcHeight = $(this).parent().find('.marker-dimension-tester').outerHeight();
                        var markerRequiredWidth = Math.min(markerMaxWidth, markerCalcWidth);
                        $(this).parent().find('.marker-dimension-tester').remove();


                        var infoBox;
                        var boxOptions = {
                            disableAutoPan: true,
                            alignBottom: true,
                            closeBoxURL: "",
                            maxWidth: 0,  // no max
                            pixelOffset: new google.maps.Size((markerRequiredWidth/2) * -1, (markerCalcHeight + 10) ),
                            infoBoxClearance: new google.maps.Size(1, 1),
                            pane: "mapPane",
                            boxStyle: {
                                opacity: 1,
                                width: markerRequiredWidth + 'px',
                                backgroundColor: "none",
                                border: "none"
                            }
                        };
                        infoBox  = new InfoBox(boxOptions);

                        var tmpMarker = dynGoogleMapObj.addMarker({
                            lat: md.lat,
                            lng: md.lng,
                            title: md.markercaption,
                            icon: markerImageCurrent,
                            click: function(evt) {
                                infoBox.setContent(contentString);
                                if(infoBox.isOpen == true){
                                    infoBox.isOpen = false;
                                    infoBox.close(dynGoogleMapObj.map, tmpMarker);
                                }
                                else
                                {
                                    infoBox.isOpen = true;
                                    infoBox.open(dynGoogleMapObj.map, tmpMarker);
                                }
                            },
                            infoBox:{
                                content:contentString
                            }
                        });
                        google.maps.event.trigger(dynGoogleMapObj.markers[i], 'click');
                    }
                    else
                    {
                        var tmpMarker = dynGoogleMapObj.addMarker({
                            lat: md.lat,
                            lng: md.lng,
                            title: md.markercaption,
                            icon: markerImageCurrent
                        });
                    }




                }
            }

            // Link?
            if(typeof $(this).parent().data('href') !== 'undefined' && $(this).parent().data('href').length > 0)
            {
                $(this).parent().on('click', function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    location.href = $(this).data('href');
                }).css('cursor', 'pointer');
                $(this).css('pointer-events', 'none');
            }


        }

        $(this).css('width', '100%');
    });

    // }catch(e){}
}
